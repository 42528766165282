import React from 'react';
import { LineChart, BarChart2, PieChart } from 'lucide-react';
import Container from '../ui/Container';
import FadeInWhenVisible from '../animations/FadeInWhenVisible';

const tools = [
  {
    icon: LineChart,
    title: 'Technical Analysis',
    description: 'Advanced charting tools with over 100 technical indicators and drawing tools'
  },
  {
    icon: BarChart2,
    title: 'Economic Calendar',
    description: 'Real-time economic events and market-moving indicators'
  },
  {
    icon: PieChart,
    title: 'Risk Management',
    description: 'Sophisticated tools for managing your trading risk and portfolio'
  }
];

const TradingTools = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Animated background grid */}
      <div className="absolute inset-0 bg-grid-pattern bg-[size:32px_32px] opacity-20" />
      
      <Container>
        <FadeInWhenVisible>
          <div className="text-center mb-16">
            <div className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6">
              <LineChart className="h-4 w-4 mr-2" />
              Professional Trading Tools
            </div>
            <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
              Everything You Need for{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
                Successful Trading
              </span>
            </h2>
            <p className="text-gray-400 text-lg max-w-2xl mx-auto">
              Access professional-grade trading tools designed to enhance your trading performance
            </p>
          </div>
        </FadeInWhenVisible>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {tools.map((tool, index) => (
            <FadeInWhenVisible key={index} delay={0.2 * index}>
              <div className="group relative">
                {/* Card background with gradient border */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity duration-300" />
                
                <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 hover:bg-gray-800/70 transition-all duration-300 border border-gray-700/50 hover:border-blue-500/50 group-hover:transform group-hover:-translate-y-1">
                  <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6 group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                    <tool.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                  </div>
                  
                  <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-blue-400 transition-colors">
                    {tool.title}
                  </h3>
                  
                  <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                    {tool.description}
                  </p>

                  {/* Decorative elements */}
                  <div className="absolute -inset-px bg-gradient-to-r from-transparent via-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg pointer-events-none" />
                </div>
              </div>
            </FadeInWhenVisible>
          ))}
        </div>
      </Container>
    </section>
  );
}

export default TradingTools;