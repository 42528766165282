import React from 'react';
import { motion } from 'framer-motion';
import { Play, Clock, Calendar, ExternalLink } from 'lucide-react';
import { cn } from '../../utils/cn';
import type { YouTubeVideo } from '../../services/youtube';

interface VideoCardProps {
  video: YouTubeVideo;
  index: number;
}

const VideoCard: React.FC<VideoCardProps> = ({ video, index }) => {
  const handleClick = () => {
    window.open(`https://www.youtube.com/watch?v=${video.id}`, '_blank');
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: index * 0.1 }}
      whileHover={{ y: -5 }}
      className="group relative cursor-pointer"
      onClick={handleClick}
    >
      {/* Ambient glow */}
      <motion.div
        className="absolute -inset-2 rounded-2xl bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-xl opacity-30 transition-opacity duration-500 group-hover:opacity-70"
        animate={{
          scale: [1, 1.1, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      {/* Card Content */}
      <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl border border-gray-700/50 group-hover:border-blue-500/50 overflow-hidden transition-all duration-300">
        {/* Thumbnail */}
        <div className="relative aspect-video">
          <img
            src={video.thumbnail}
            alt={video.title}
            className="w-full h-full object-cover transition-transform duration-500 group-hover:scale-110"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/80 via-black/40 to-transparent" />
          
          {/* Play Button Overlay */}
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            whileHover={{ scale: 1.1 }}
            animate={{ opacity: 1, scale: 1 }}
            className="absolute inset-0 flex items-center justify-center"
          >
            <div className="w-16 h-16 flex items-center justify-center rounded-full bg-gradient-to-r from-blue-500 to-purple-500 text-white opacity-0 group-hover:opacity-100 transition-all duration-300 shadow-lg shadow-blue-500/25">
              <Play className="h-8 w-8" />
            </div>
          </motion.div>

          {/* Duration Badge */}
          <div className="absolute bottom-3 right-3 flex items-center gap-1.5 px-3 py-1.5 rounded-full bg-black/60 text-white text-sm backdrop-blur-sm border border-white/10">
            <Clock className="h-3.5 w-3.5" />
            {video.duration}
          </div>
        </div>

        {/* Content */}
        <div className="p-6">
          <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-blue-400 transition-colors line-clamp-2">
            {video.title}
          </h3>
          <p className="text-gray-400 mb-4 group-hover:text-gray-300 transition-colors line-clamp-3">
            {video.description}
          </p>
          
          <div className="flex items-center justify-between">
            {/* Date */}
            <div className="flex items-center gap-2 text-sm text-gray-500">
              <Calendar className="h-4 w-4" />
              {new Date(video.publishedAt).toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </div>

            {/* Watch Button */}
            <motion.div
              whileHover={{ x: 5 }}
              className="flex items-center gap-1 text-blue-400 text-sm font-medium"
            >
              Watch Now
              <ExternalLink className="h-4 w-4" />
            </motion.div>
          </div>
        </div>

        {/* Shimmer effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/5 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000 ease-out" />
      </div>
    </motion.div>
  );
};

export default VideoCard;