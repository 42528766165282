import React from 'react';
import { motion } from 'framer-motion';
import { LineChart, Bot, Globe, Shield, Zap, Lock } from 'lucide-react';

const features = [
  {
    icon: LineChart,
    title: 'MT4 Compatibility',
    description: 'Full compatibility with Expert Advisors (EA) and all MT4 features',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  },
  {
    icon: Bot,
    title: 'Expert Advisors',
    description: 'Deploy and run your automated trading strategies seamlessly',
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    icon: Globe,
    title: 'Cross-Platform',
    description: 'Available on Windows, Mac, mobile devices, and web browsers',
    gradient: 'from-green-500/20 to-emerald-500/20'
  },
  {
    icon: Shield,
    title: 'Secure Transfers',
    description: 'Internal transfers between Hankotrade and MagnoFx via support',
    gradient: 'from-orange-500/20 to-red-500/20'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Lightning-fast trade execution with minimal latency',
    gradient: 'from-yellow-500/20 to-orange-500/20'
  },
  {
    icon: Lock,
    title: 'Enhanced Security',
    description: 'Bank-grade encryption for all your trading activities',
    gradient: 'from-indigo-500/20 to-blue-500/20'
  }
];

const MTX4Features = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
      {features.map((feature, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="group relative"
          whileHover={{ y: -5 }}
        >
          <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 hover:bg-gray-800/70 transition-all duration-300 border border-gray-700/50 hover:border-blue-500/50 h-full">
            <div className={`flex items-center justify-center w-14 h-14 rounded-lg bg-gradient-to-br ${feature.gradient} mb-6 transition-transform duration-300`}>
              <feature.icon className="h-7 w-7 text-white" />
            </div>
            
            <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-blue-400 transition-colors">
              {feature.title}
            </h3>
            
            <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
              {feature.description}
            </p>

            {/* Subtle hover effect */}
            <div className="absolute inset-0 rounded-lg">
              <div className="absolute inset-0 rounded-lg border-2 border-transparent bg-gradient-to-r from-transparent via-blue-500/10 to-transparent -translate-x-full group-hover:translate-x-full transition-transform duration-1000" />
            </div>
          </div>
        </motion.div>
      ))}
    </div>
  );
};

export default MTX4Features;