import React from 'react';
import { Menu, X, LogIn } from 'lucide-react';
import { Link, useLocation } from 'react-router-dom';
import Container from '../ui/Container';
import Button from '../ui/Button';
import { TRADING_URL } from '../../utils/constants';

const navLinks = [
  { href: '/', label: 'Home' },
  { href: '/#markets', label: 'Markets' },
  { href: '/#trading', label: 'Trading' },
  { href: '/#accounts', label: 'Accounts' },
  { href: '/mtx4', label: 'MTX4', badge: 'New' },
  { href: '/guides', label: 'Education' }
];

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const location = useLocation();

  const handleNavClick = (href: string) => {
    setIsOpen(false);
    
    if (href.startsWith('/#')) {
      // If we're not on the home page, navigate there first
      if (location.pathname !== '/') {
        window.location.href = href;
        return;
      }
      
      // Handle smooth scroll to section
      const element = document.querySelector(href.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  return (
    <nav className="fixed w-full bg-black/80 backdrop-blur-md z-50">
      <Container>
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/" className="flex-shrink-0">
              <img 
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoTrade"
                className="h-8"
              />
            </Link>
            <div className="hidden md:block ml-10">
              <div className="flex items-baseline space-x-4">
                {navLinks.map((link) => (
                  <Link
                    key={link.label}
                    to={link.href.replace('/#', '/')}
                    onClick={(e) => {
                      if (link.href.startsWith('/#')) {
                        e.preventDefault();
                        handleNavClick(link.href);
                      }
                    }}
                    className="relative text-gray-300 hover:text-white px-3 py-2 rounded-md text-sm font-medium transition-colors"
                  >
                    {link.label}
                    {link.badge && (
                      <span className="absolute -top-1 -right-2 px-1.5 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                        {link.badge}
                      </span>
                    )}
                  </Link>
                ))}
              </div>
            </div>
          </div>

          <div className="hidden md:flex items-center space-x-4">
            <Button
              variant="ghost"
              icon={LogIn}
              href={TRADING_URL}
            >
              Sign In
            </Button>
            <Button href={TRADING_URL}>
              Start Trading
            </Button>
          </div>

          <div className="md:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none"
            >
              {isOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
            </button>
          </div>
        </div>

        {/* Mobile menu */}
        {isOpen && (
          <div className="md:hidden">
            <div className="px-2 pt-2 pb-3 space-y-1">
              {navLinks.map((link) => (
                <Link
                  key={link.label}
                  to={link.href.replace('/#', '/')}
                  onClick={(e) => {
                    if (link.href.startsWith('/#')) {
                      e.preventDefault();
                      handleNavClick(link.href);
                    }
                  }}
                  className="text-gray-300 hover:text-white block px-3 py-2 rounded-md text-base font-medium"
                >
                  {link.label}
                  {link.badge && (
                    <span className="ml-2 px-1.5 py-0.5 text-xs font-medium bg-blue-500 text-white rounded-full">
                      {link.badge}
                    </span>
                  )}
                </Link>
              ))}
              <Button
                variant="ghost"
                icon={LogIn}
                href={TRADING_URL}
                className="w-full mt-2"
              >
                Sign In
              </Button>
              <Button 
                href={TRADING_URL} 
                className="w-full mt-2"
              >
                Start Trading
              </Button>
            </div>
          </div>
        )}
      </Container>
    </nav>
  );
};

export default Navbar;