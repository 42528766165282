import React from 'react';
import { motion } from 'framer-motion';
import { UserPlus, ArrowRight, Wallet, Download } from 'lucide-react';
import Button from '../ui/Button';
import { GradientText } from '../ui/GradientText';

const steps = [
  {
    icon: UserPlus,
    title: 'Create MTX4 Live Account',
    description: 'Visit login.hankotrade.com/live-account to create your new MTX4 live account',
    buttonText: 'Create Account',
  },
  {
    icon: ArrowRight,
    title: 'Auto-Redirect to MagnoFx',
    description: "You'll be automatically signed up and redirected to MagnoFx.com",
    buttonText: 'Visit MagnoFx',
  },
  {
    icon: Wallet,
    title: 'Fund Your Account',
    description: 'Fund your trading account directly through MagnoFx.com platform',
    buttonText: 'Fund Account',
  },
  {
    icon: Download,
    title: 'Download MTX4',
    description: 'Download and install the MTX4 platform for Windows desktop',
    buttonText: 'Download MTX4',
  }
];

const MTX4Steps = () => {
  return (
    <div className="py-24 border-t border-gray-800/50">
      <div className="text-center mb-12">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="inline-flex items-center bg-blue-500/10 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
        >
          <Download className="h-4 w-4 mr-2" />
          Get Started with MTX4
        </motion.div>

        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.1 }}
          className="text-3xl md:text-4xl font-bold text-white mb-4"
        >
          Start Your Trading Journey with{' '}
          <GradientText>MTX4</GradientText>
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2 }}
          className="text-gray-400 text-lg max-w-2xl mx-auto"
        >
          Follow these simple steps to begin your trading journey with our enhanced MTX4 platform
        </motion.p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        {steps.map((step, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 + 0.3 }}
          >
            <div className="relative group h-full">
              {/* Background glow effect */}
              <div className="absolute -inset-0.5 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur opacity-75 group-hover:opacity-100 transition duration-300" />
              
              {/* Card content */}
              <div className="relative h-full bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50 group-hover:border-blue-500/50 transition duration-300">
                {/* Icon */}
                <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6 group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                  <step.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                </div>
                
                {/* Content */}
                <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                  {step.title}
                </h3>
                
                <p className="text-gray-400 mb-6 flex-grow group-hover:text-gray-300 transition-colors">
                  {step.description}
                </p>

                {/* Button */}
                <Button
                  href="https://login.hankotrade.com/"
                  className="w-full justify-center"
                  icon={ArrowRight}
                  iconPosition="right"
                >
                  {step.buttonText}
                </Button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MTX4Steps;