import React from 'react';
import { motion, useReducedMotion } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { cn } from '../../utils/cn';

interface FadeInWhenVisibleProps {
  children: React.ReactNode;
  delay?: number;
  className?: string;
  slideOffset?: number;
}

const FadeInWhenVisible = ({ 
  children, 
  delay = 0,
  className = '',
  slideOffset = 50
}: FadeInWhenVisibleProps) => {
  const shouldReduceMotion = useReducedMotion();
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  const variants = {
    hidden: { 
      opacity: 0,
      y: shouldReduceMotion ? 0 : slideOffset
    },
    visible: { 
      opacity: 1,
      y: 0
    }
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? "visible" : "hidden"}
      transition={{ 
        duration: 0.5, 
        delay,
        ease: "easeOut"
      }}
      className={cn("will-change-transform", className)}
    >
      {children}
    </motion.div>
  );
};

export default FadeInWhenVisible;