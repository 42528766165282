import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Lock, Server } from 'lucide-react';
import Container from '../ui/Container';
import { GradientText } from '../ui/GradientText';

const securityFeatures = [
  {
    icon: Shield,
    title: 'Asset Protection',
    description: 'Your funds are held in segregated accounts at tier-1 banks',
    gradient: 'from-blue-500/20 to-cyan-500/20'
  },
  {
    icon: Lock,
    title: 'Secure Infrastructure',
    description: 'Military-grade encryption for all transactions and personal data',
    gradient: 'from-purple-500/20 to-pink-500/20'
  },
  {
    icon: Server,
    title: 'Reliable Systems',
    description: '99.9% uptime with redundant server infrastructure',
    gradient: 'from-green-500/20 to-emerald-500/20'
  }
];

const SecuritySection = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Background grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.05)_1px,transparent_1px)] bg-[size:32px_32px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />
      
      <Container>
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
          >
            <Shield className="h-4 w-4 mr-2" />
            Your Security is Our Priority
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="text-3xl md:text-4xl font-bold text-white mb-4"
          >
            Trade with Complete{' '}
            <GradientText>Peace of Mind</GradientText>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Your security is our top priority. We employ industry-leading security measures to protect your funds and data.
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          {securityFeatures.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 + 0.3 }}
              className="group relative"
              whileHover={{ y: -5 }}
            >
              {/* Animated glow effect */}
              <motion.div
                className={`absolute -inset-px rounded-xl bg-gradient-to-r ${feature.gradient} blur-xl opacity-50 group-hover:opacity-75 transition-opacity duration-300`}
                animate={{
                  scale: [1, 1.1, 1],
                  opacity: [0.5, 0.7, 0.5],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />

              <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-8 border border-gray-700/50 group-hover:border-blue-500/50 h-full">
                {/* Animated icon */}
                <motion.div
                  className={`flex items-center justify-center w-14 h-14 rounded-lg bg-gradient-to-br ${feature.gradient} mb-6`}
                  animate={{
                    scale: [1, 1.1, 1],
                    rotate: [0, 5, 0],
                  }}
                  transition={{
                    duration: 4,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                >
                  <feature.icon className="h-7 w-7 text-white" />
                </motion.div>

                <h3 className="text-xl font-semibold text-white mb-3 group-hover:text-blue-400 transition-colors">
                  {feature.title}
                </h3>

                <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                  {feature.description}
                </p>

                {/* Animated line effect */}
                <div className="absolute bottom-0 left-0 w-full h-[2px] overflow-hidden">
                  <motion.div
                    className="w-full h-full bg-gradient-to-r from-transparent via-blue-500 to-transparent"
                    animate={{
                      x: ['-100%', '100%'],
                    }}
                    transition={{
                      duration: 2,
                      repeat: Infinity,
                      ease: "linear",
                      delay: index * 0.5
                    }}
                  />
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Security badge */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.8 }}
          className="mt-16 text-center"
        >
          <div className="inline-flex items-center gap-2 px-4 py-2 rounded-full bg-green-500/10 text-green-400 border border-green-500/20">
            <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse" />
            <span className="text-sm font-medium">Secured by Industry-Leading Protection</span>
          </div>
        </motion.div>
      </Container>
    </section>
  );
};

export default SecuritySection;