import React from 'react';

const MobileAppImage: React.FC = () => {
  return (
    <div className="relative">
      <div className="relative z-10">
        <div className="relative rounded-2xl overflow-hidden shadow-2xl">
          <img
            src="https://scontent-den2-1.cdninstagram.com/v/t39.30808-6/465868421_8910870015629819_2455409577381017693_n.jpg?stp=dst-jpg_e15_tt6&efg=eyJ2ZW5jb2RlX3RhZyI6ImltYWdlX3VybGdlbi4xMTc5eDExODguc2RyLmYzMDgwOC5kZWZhdWx0X2ltYWdlIn0&_nc_ht=scontent-den2-1.cdninstagram.com&_nc_cat=111&_nc_ohc=jNJOdf4QHekQ7kNvgESWGxA&_nc_gid=dde66fe87af048488b355cfb03d5b197&edm=APs17CUAAAAA&ccb=7-5&ig_cache_key=MzUwMDU4NDQzOTI3MzQ5MDAyMg%3D%3D.3-ccb7-5&oh=00_AYBpqbj2EBafTxNPXSDNVHrrGsR_xEvw22KVlqqG5Nu1wg&oe=67613CCB&_nc_sid=10d13b"
            alt="HankoX Mobile Trading App"
            className="w-full h-auto rounded-2xl hover:scale-105 transition-transform duration-500"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
          <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-overlay" />
        </div>
      </div>
      
      {/* Static decorative elements */}
      <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl opacity-50 rounded-full" />
      <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-blue-500/20 to-purple-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
      <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-purple-500/20 to-blue-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(59,130,246,0.1)_1px,transparent_1px)] bg-[size:20px_20px]" />
    </div>
  );
};

export default MobileAppImage;