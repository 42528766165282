interface SpreadInfo {
  label: string;
  color: string;
  textColor: string;
  borderColor: string;
  pulseColor: string;
}

export function getSpreadInfo(spread: number): SpreadInfo {
  if (spread <= 0.0001) {
    return {
      label: 'Ultra Tight',
      color: 'from-green-500/20 to-emerald-500/20',
      textColor: 'text-green-400',
      borderColor: 'border-green-500/30',
      pulseColor: 'bg-green-400'
    };
  }
  
  if (spread <= 0.0003) {
    return {
      label: 'Tight',
      color: 'from-blue-500/20 to-cyan-500/20',
      textColor: 'text-blue-400',
      borderColor: 'border-blue-500/30',
      pulseColor: 'bg-blue-400'
    };
  }
  
  return {
    label: 'Competitive',
    color: 'from-purple-500/20 to-blue-500/20',
    textColor: 'text-purple-400',
    borderColor: 'border-purple-500/30',
    pulseColor: 'bg-purple-400'
  };
}