import React from 'react';
import Hero from '../components/hero/Hero';
import Features from '../components/features/Features';
import Markets from '../components/sections/Markets';
import FXMarket from '../components/sections/FXMarket';
import TradingPlatform from '../components/sections/TradingPlatform';
import TradingTools from '../components/sections/TradingTools';
import SecuritySection from '../components/sections/SecuritySection';
import MobileAppSection from '../components/sections/mobile-app/MobileAppSection';
import CopyTradingSection from '../components/sections/copy-trading/CopyTradingSection';
import AccountTypes from '../components/sections/AccountTypes';
import EducationSection from '../components/sections/EducationSection';
import DiscordCommunity from '../components/sections/DiscordCommunity';

const Home = () => {
  return (
    <div className="min-h-screen bg-black text-white">
      <Hero />
      <section id="markets">
        <Markets />
        <FXMarket />
      </section>
      <section id="trading">
        <TradingPlatform />
        <TradingTools />
        <SecuritySection />
        <CopyTradingSection />
      </section>
      <MobileAppSection />
      <section id="accounts">
        <AccountTypes />
      </section>
      <section id="education">
        <EducationSection />
      </section>
      <DiscordCommunity />
    </div>
  );
};

export default Home;