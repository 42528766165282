import React from 'react';
import { cn } from '../../utils/cn';

interface GradientTextProps {
  children: React.ReactNode;
  className?: string;
}

export const GradientText: React.FC<GradientTextProps> = ({
  children,
  className
}) => {
  return (
    <span className={cn(
      "bg-clip-text text-transparent bg-gradient-to-r",
      "from-blue-400 via-blue-500 to-purple-600",
      className
    )}>
      {children}
    </span>
  );
};