import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';
import { Link } from 'react-router-dom';
import { cn } from '../../utils/cn';
import { TRADING_URL } from '../../utils/constants';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary' | 'outline' | 'ghost';
  size?: 'sm' | 'md' | 'lg';
  icon?: LucideIcon;
  iconPosition?: 'left' | 'right';
  loading?: boolean;
  href?: string;
  to?: string;
  external?: boolean;
  children: React.ReactNode;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(({
  variant = 'primary',
  size = 'md',
  icon: Icon,
  iconPosition = 'right',
  loading = false,
  href = TRADING_URL,
  to,
  external = true,
  children,
  className = '',
  disabled,
  onClick,
  ...props
}, ref) => {
  const baseStyles = "relative inline-flex items-center justify-center font-medium rounded-lg transition-all duration-300 ease-out";
  
  const variants = {
    primary: "bg-gradient-to-r from-blue-600 to-blue-500 hover:from-blue-700 hover:to-blue-600 text-white shadow-lg shadow-blue-500/25 hover:shadow-blue-600/25",
    secondary: "bg-gradient-to-r from-gray-700 to-gray-600 hover:from-gray-800 hover:to-gray-700 text-white shadow-lg shadow-gray-800/10",
    outline: "border-2 border-blue-500 text-blue-500 hover:bg-blue-500 hover:text-white",
    ghost: "text-gray-300 hover:text-white hover:bg-gray-800/50"
  };

  const sizes = {
    sm: "text-sm px-4 py-2 gap-1.5",
    md: "text-base px-6 py-2.5 gap-2",
    lg: "text-lg px-8 py-3 gap-2.5"
  };

  const isDisabled = disabled || loading;

  const content = (
    <>
      {loading && (
        <svg className="animate-spin h-5 w-5 mr-2" viewBox="0 0 24 24">
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
          <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
        </svg>
      )}

      {Icon && iconPosition === 'left' && !loading && (
        <Icon className="h-5 w-5" />
      )}

      <span className="relative">
        {children}
      </span>

      {Icon && iconPosition === 'right' && !loading && (
        <Icon className="h-5 w-5 transform group-hover:translate-x-0.5 transition-transform" />
      )}

      <div className="absolute inset-0 rounded-lg bg-gradient-to-r from-white/0 via-white/10 to-white/0 opacity-0 group-hover:opacity-100 -translate-x-full group-hover:translate-x-full transition-all duration-700 ease-out pointer-events-none" />
    </>
  );

  const commonProps = {
    className: cn(
      baseStyles,
      variants[variant],
      sizes[size],
      isDisabled ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer',
      'group',
      className
    ),
    onClick: !isDisabled ? onClick : undefined,
  };

  const motionProps = {
    whileHover: !isDisabled ? { scale: 1.02 } : undefined,
    whileTap: !isDisabled ? { scale: 0.98 } : undefined,
  };

  // Internal routing using React Router
  if (to) {
    return (
      <motion.div {...motionProps}>
        <Link to={to} {...commonProps}>
          {content}
        </Link>
      </motion.div>
    );
  }

  // External link
  if (href) {
    return (
      <motion.a
        href={href}
        target={external ? "_blank" : undefined}
        rel={external ? "noopener noreferrer" : undefined}
        {...commonProps}
        {...motionProps}
      >
        {content}
      </motion.a>
    );
  }

  // Regular button
  return (
    <motion.button
      ref={ref}
      disabled={isDisabled}
      {...commonProps}
      {...motionProps}
      {...props}
    >
      {content}
    </motion.button>
  );
});

Button.displayName = 'Button';

export default Button;