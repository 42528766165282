import React from 'react';
import { motion } from 'framer-motion';
import { Users, ChevronRight, TrendingUp, Shield, Clock } from 'lucide-react';
import Container from '../../ui/Container';
import Button from '../../ui/Button';
import FadeInWhenVisible from '../../animations/FadeInWhenVisible';

const features = [
  {
    icon: TrendingUp,
    title: "Follow Top Traders",
    description: "Copy successful traders automatically and learn from their strategies"
  },
  {
    icon: Shield,
    title: "Risk Management",
    description: "Set your own risk levels and investment amounts"
  },
  {
    icon: Clock,
    title: "Real-Time Sync",
    description: "Trades are copied instantly with minimal latency"
  }
];

const CopyTradingSection = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Animated background grid */}
      <div className="absolute inset-0 bg-grid-pattern bg-[size:32px_32px] opacity-20" />
      
      <Container>
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <FadeInWhenVisible>
            <div className="relative">
              <motion.div
                initial={{ scale: 0.95, opacity: 0 }}
                animate={{ scale: 1, opacity: 1 }}
                transition={{ duration: 0.6 }}
                className="relative z-10"
              >
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <img
                    src="https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-142926.png"
                    alt="Copy Trading Platform"
                    className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-overlay" />
                </div>
              </motion.div>

              {/* Decorative elements */}
              <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl opacity-50 rounded-full" />
              <motion.div
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.3, 0.2, 0.3],
                }}
                transition={{
                  duration: 8,
                  repeat: Infinity,
                  ease: "linear"
                }}
                className="absolute -right-8 top-1/4 w-16 h-16 bg-blue-500/10 backdrop-blur-lg rounded-xl border border-blue-500/20"
              />
              <motion.div
                animate={{
                  scale: [1, 1.3, 1],
                  opacity: [0.2, 0.1, 0.2],
                }}
                transition={{
                  duration: 10,
                  repeat: Infinity,
                  ease: "linear",
                  delay: 1
                }}
                className="absolute -left-8 bottom-1/4 w-20 h-20 bg-purple-500/10 backdrop-blur-lg rounded-xl border border-purple-500/20"
              />
            </div>
          </FadeInWhenVisible>

          <FadeInWhenVisible delay={0.2}>
            <div>
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.6 }}
                className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
              >
                <Users className="h-4 w-4 mr-2" />
                Copy Trading • HankoX
              </motion.div>

              <motion.h2
                className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.2 }}
              >
                Copy Trade with{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600 animate-gradient">
                  Professional Traders
                </span>
              </motion.h2>

              <motion.p
                className="text-gray-300 text-lg mb-8 leading-relaxed"
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.3 }}
              >
                Start copying successful traders instantly. Perfect for both new and experienced traders looking to diversify their trading strategy. Get access to a community of professional traders and replicate their success.
              </motion.p>

              <div className="space-y-6 mb-8">
                {features.map((feature, index) => (
                  <motion.div
                    key={index}
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.5, delay: 0.4 + index * 0.1 }}
                    className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
                  >
                    <div className="flex-shrink-0 p-2 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-lg group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                      <feature.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                    </div>
                    <div>
                      <h3 className="text-white font-semibold mb-1">{feature.title}</h3>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </motion.div>
                ))}
              </div>

              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.6, delay: 0.7 }}
              >
                <Button 
                  className="group"
                  icon={ChevronRight}
                >
                  Start Copy Trading
                </Button>
              </motion.div>

              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.6, delay: 0.8 }}
                className="mt-8 flex flex-wrap gap-3"
              >
                <span className="px-3 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm border border-blue-500/20">
                  100+ Professional Traders
                </span>
                <span className="px-3 py-1 bg-purple-500/10 rounded-full text-purple-400 text-sm border border-purple-500/20">
                  Automated Copying
                </span>
                <span className="px-3 py-1 bg-green-500/10 rounded-full text-green-400 text-sm border border-green-500/20">
                  Risk Management
                </span>
              </motion.div>
            </div>
          </FadeInWhenVisible>
        </div>
      </Container>
    </section>
  );
};

export default CopyTradingSection;