import { useState, useEffect } from 'react';

interface UseSpreadAnimationProps {
  initialSpread: number;
  updateInterval?: number;
}

export function useSpreadAnimation({ 
  initialSpread, 
  updateInterval = 800 
}: UseSpreadAnimationProps) {
  const [spread, setSpread] = useState(initialSpread);
  const [trend, setTrend] = useState<'up' | 'down'>('up');

  useEffect(() => {
    const interval = setInterval(() => {
      const random = Math.random();
      let newSpread;
      
      if (random < 0.4) {
        newSpread = Math.random() * 0.0001;
      } else if (random < 0.7) {
        newSpread = 0.0001 + Math.random() * 0.0002;
      } else {
        newSpread = 0.0003 + Math.random() * 0.0117;
      }
      
      setSpread(newSpread);
      setTrend(newSpread > spread ? 'up' : 'down');
    }, updateInterval);

    return () => clearInterval(interval);
  }, [spread, updateInterval]);

  return { spread, trend };
}