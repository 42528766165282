import React from 'react';
import { motion } from 'framer-motion';
import { Mail, MessageSquare, Clock } from 'lucide-react';
import Button from '../ui/Button';

const MTX4Support = () => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="lg:col-span-2 relative"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50" />
        
        <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50">
          <h3 className="text-2xl font-bold text-white mb-4">Need Assistance?</h3>
          <p className="text-gray-400 mb-6">
            Our dedicated support team is here to help you with any questions about MTX4 platform, 
            account management, or internal transfers between Hankotrade and MagnoFx.
          </p>
          
          <div className="flex flex-wrap gap-4">
            <Button
              variant="primary"
              icon={Mail}
              href="mailto:support@hankotrade.com"
              className="flex-1"
            >
              support@hankotrade.com
            </Button>
            <Button
              variant="secondary"
              icon={MessageSquare}
              className="flex-1"
            >
              Live Chat Support
            </Button>
          </div>
        </div>
      </motion.div>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        className="relative"
      >
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50" />
        
        <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 border border-gray-700/50">
          <h3 className="text-xl font-bold text-white mb-4">Support Hours</h3>
          <div className="space-y-4">
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Trading Support:</span>
              <span className="text-white">24/7</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Technical Support:</span>
              <span className="text-white">24/7</span>
            </div>
            <div className="flex justify-between items-center">
              <span className="text-gray-400">Account Management:</span>
              <span className="text-white">24/7</span>
            </div>
          </div>
        </div>
      </motion.div>
    </div>
  );
};

export default MTX4Support;