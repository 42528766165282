import React from 'react';
import { Monitor, Smartphone, Lock, Zap, ChevronRight, LineChart, BarChart2 } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import FadeInWhenVisible from '../animations/FadeInWhenVisible';

const platformFeatures = [
  {
    icon: LineChart,
    title: 'Advanced Charting',
    description: 'Professional-grade charting with 100+ technical indicators and drawing tools'
  },
  {
    icon: BarChart2,
    title: 'Market Analysis',
    description: 'Real-time market data and comprehensive analysis tools'
  },
  {
    icon: Lock,
    title: 'Secure Trading',
    description: 'Bank-grade security with encrypted transactions'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Lightning-fast order execution with no requotes'
  }
];

const TradingPlatform = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Animated background grid */}
      <div className="absolute inset-0 bg-grid-pattern bg-[size:32px_32px] opacity-20" />
      
      <Container>
        <div className="grid lg:grid-cols-2 gap-16 items-center">
          <FadeInWhenVisible>
            <div>
              <div className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6">
                <Monitor className="h-4 w-4 mr-2" />
                Advanced Trading Platform
              </div>

              <h2 className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight">
                Professional-Grade{' '}
                <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
                  Trading Tools
                </span>
              </h2>

              <p className="text-gray-300 text-lg mb-8 leading-relaxed">
                Experience professional-grade trading tools designed for both novice and experienced traders. Our platform provides everything you need for successful trading.
              </p>

              <div className="space-y-6 mb-8">
                {platformFeatures.map((feature, index) => (
                  <div
                    key={index}
                    className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
                  >
                    <div className="flex-shrink-0 p-2 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-lg group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                      <feature.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                    </div>
                    <div>
                      <h3 className="text-white font-semibold mb-1">{feature.title}</h3>
                      <p className="text-gray-400">{feature.description}</p>
                    </div>
                  </div>
                ))}
              </div>

              <Button 
                className="group"
                icon={ChevronRight}
              >
                Explore Platform
              </Button>

              <div className="mt-8 flex flex-wrap gap-3">
                <span className="px-3 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm border border-blue-500/20">
                  Multi-Asset Trading
                </span>
                <span className="px-3 py-1 bg-purple-500/10 rounded-full text-purple-400 text-sm border border-purple-500/20">
                  Advanced Analytics
                </span>
                <span className="px-3 py-1 bg-green-500/10 rounded-full text-green-400 text-sm border border-green-500/20">
                  24/7 Support
                </span>
              </div>
            </div>
          </FadeInWhenVisible>

          <FadeInWhenVisible delay={0.2}>
            <div className="relative">
              <div className="relative z-10">
                <div className="relative rounded-2xl overflow-hidden shadow-2xl">
                  <img 
                    src="https://trustedsignals.com/wp-content/uploads/2024/12/Screenshot-2024-12-12-143059.png"
                    alt="Advanced Trading Platform Interface"
                    className="w-full h-auto rounded-2xl transform hover:scale-105 transition-transform duration-500"
                  />
                  <div className="absolute inset-0 bg-gradient-to-t from-black/40 via-black/20 to-transparent" />
                  <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 mix-blend-overlay" />
                </div>
              </div>

              {/* Decorative elements */}
              <div className="absolute -inset-4 bg-gradient-to-r from-blue-500/20 to-purple-500/20 blur-3xl opacity-50 rounded-full" />
              <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-blue-500/20 to-purple-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
              <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-purple-500/20 to-blue-500/20 backdrop-blur-lg rounded-xl border border-white/10" />
              <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(59,130,246,0.1)_1px,transparent_1px)] bg-[size:20px_20px]" />
            </div>
          </FadeInWhenVisible>
        </div>
      </Container>
    </section>
  );
};

export default TradingPlatform;