import React from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, DollarSign, Bitcoin, Coins } from 'lucide-react';
import Container from '../ui/Container';
import FadeInWhenVisible from '../animations/FadeInWhenVisible';
import TradingIcons from './markets/TradingIcons';

const marketData = [
  {
    name: 'Forex',
    icon: DollarSign,
    pairs: '50+ Currency Pairs',
    description: 'Trade major, minor, and exotic currency pairs with competitive spreads'
  },
  {
    name: 'Crypto',
    icon: Bitcoin,
    pairs: '20+ Cryptocurrencies',
    description: 'Access the most popular cryptocurrencies with 24/7 trading'
  },
  {
    name: 'Commodities',
    icon: Coins,
    pairs: 'Gold, Silver & More',
    description: 'Trade precious metals and energy products with low spreads'
  },
  {
    name: 'Indices',
    icon: TrendingUp,
    pairs: 'Global Indices',
    description: 'Trade major stock indices from around the world'
  }
];

const Markets = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Animated background grid */}
      <div className="absolute inset-0 bg-grid-pattern bg-[size:32px_32px] opacity-20" />
      
      <Container>
        <FadeInWhenVisible>
          {/* Trading Icons */}
          <TradingIcons />

          <div className="text-center mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
            >
              <TrendingUp className="h-4 w-4 mr-2" />
              Multiple Markets • One Platform
            </motion.div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="text-3xl md:text-4xl font-bold text-white mb-4"
            >
              Diverse{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
                Trading Markets
              </span>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-gray-400 text-lg max-w-2xl mx-auto"
            >
              Trade across multiple markets with competitive spreads and advanced trading tools
            </motion.p>
          </div>
        </FadeInWhenVisible>
        
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {marketData.map((market, index) => (
            <FadeInWhenVisible key={index} delay={0.2 + index * 0.1}>
              <motion.div
                whileHover={{ y: -5 }}
                className="group relative"
              >
                {/* Card background with gradient border */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity duration-300" />
                
                <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 hover:bg-gray-800/70 transition-all duration-300 border border-gray-700/50 hover:border-blue-500/50">
                  <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6 group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                    <market.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                  </div>
                  
                  <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                    {market.name}
                  </h3>
                  
                  <p className="text-blue-400 font-medium mb-2 group-hover:text-blue-300 transition-colors">
                    {market.pairs}
                  </p>
                  
                  <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                    {market.description}
                  </p>

                  {/* Decorative elements */}
                  <div className="absolute -inset-px bg-gradient-to-r from-transparent via-blue-500/10 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-500 rounded-lg pointer-events-none" />
                </div>
              </motion.div>
            </FadeInWhenVisible>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default Markets;