import React from 'react';
import { motion } from 'framer-motion';
import { MessageSquare, Users, Zap, Globe, ChevronRight } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import { GradientText } from '../ui/GradientText';

const features = [
  {
    icon: Users,
    title: 'Trading Community',
    description: 'Connect with experienced traders and share strategies'
  },
  {
    icon: Zap,
    title: 'Real-time Updates',
    description: 'Get instant market updates and trading signals'
  },
  {
    icon: Globe,
    title: 'Global Network',
    description: 'Join traders from around the world 24/7'
  }
];

const DiscordCommunity = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
      {/* Background grid pattern */}
      <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.05)_1px,transparent_1px)] bg-[size:32px_32px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />
      
      <Container>
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Content */}
          <div>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
            >
              <MessageSquare className="h-4 w-4 mr-2" />
              Join Our Community
            </motion.div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.1 }}
              className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight"
            >
              Connect with the{' '}
              <GradientText>HankoX Community</GradientText>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="text-gray-300 text-lg mb-8 leading-relaxed"
            >
              Join our vibrant Discord community of traders. Share strategies, get market insights, and connect with fellow traders from around the world.
            </motion.p>

            <div className="space-y-6 mb-8">
              {features.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3 + index * 0.1 }}
                  className="flex items-start gap-4 group hover:translate-x-1 transition-transform duration-300"
                >
                  <div className="flex-shrink-0 p-2 bg-gradient-to-br from-[#5865F2]/20 to-[#7289DA]/20 rounded-lg group-hover:from-[#5865F2]/30 group-hover:to-[#7289DA]/30 transition-colors">
                    <feature.icon className="h-6 w-6 text-[#5865F2] group-hover:text-[#7289DA]" />
                  </div>
                  <div>
                    <h3 className="text-white font-medium mb-1">{feature.title}</h3>
                    <p className="text-gray-400">{feature.description}</p>
                  </div>
                </motion.div>
              ))}
            </div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.7 }}
            >
              <Button 
                href="https://discord.gg/EP394qGPDu"
                className="group bg-[#5865F2] hover:bg-[#7289DA]"
                icon={ChevronRight}
              >
                Join Discord Community
              </Button>
            </motion.div>
          </div>

          {/* Discord Preview */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.4 }}
            className="relative"
          >
            <div className="relative rounded-2xl overflow-hidden shadow-2xl">
              {/* Discord-style UI mockup */}
              <div className="bg-[#36393F] p-8 rounded-2xl">
                <div className="flex items-center gap-4 mb-8">
                  <div className="w-16 h-16 rounded-full bg-gradient-to-br from-[#5865F2] to-[#7289DA] flex items-center justify-center">
                    <MessageSquare className="h-8 w-8 text-white" />
                  </div>
                  <div>
                    <h3 className="text-white text-xl font-bold mb-1">HankoX Trading</h3>
                    <p className="text-gray-400">Official Trading Community</p>
                  </div>
                </div>

                {/* Member count and status */}
                <div className="space-y-4 mb-8">
                  <div className="flex items-center gap-3 text-gray-300">
                    <Users className="h-5 w-5 text-[#5865F2]" />
                    <span>10,000+ Members Online</span>
                  </div>
                  <div className="flex items-center gap-3 text-gray-300">
                    <Globe className="h-5 w-5 text-[#5865F2]" />
                    <span>Active 24/7</span>
                  </div>
                </div>

                {/* Channel preview */}
                <div className="space-y-2">
                  <div className="bg-[#2F3136] p-3 rounded-lg text-gray-300">
                    <span className="text-[#5865F2]">#trading-signals</span>
                  </div>
                  <div className="bg-[#2F3136] p-3 rounded-lg text-gray-300">
                    <span className="text-[#5865F2]">#market-analysis</span>
                  </div>
                  <div className="bg-[#2F3136] p-3 rounded-lg text-gray-300">
                    <span className="text-[#5865F2]">#community-chat</span>
                  </div>
                </div>
              </div>
            </div>

            {/* Decorative elements */}
            <div className="absolute -inset-4 bg-gradient-to-r from-[#5865F2]/20 to-[#7289DA]/20 blur-3xl opacity-50 rounded-full" />
            <div className="absolute -right-8 top-1/4 w-16 h-16 bg-gradient-to-br from-[#5865F2]/20 to-[#7289DA]/20 backdrop-blur-lg rounded-xl border border-white/10" />
            <div className="absolute -left-8 bottom-1/4 w-20 h-20 bg-gradient-to-tr from-[#7289DA]/20 to-[#5865F2]/20 backdrop-blur-lg rounded-xl border border-white/10" />
          </motion.div>
        </div>
      </Container>
    </section>
  );
};

export default DiscordCommunity;