import React from 'react';
import { motion } from 'framer-motion';
import SpreadStatus from './SpreadStatus';

interface CurrencyPairRowProps {
  pair: string;
  spread: number;
  index: number;
}

const CurrencyPairRow: React.FC<CurrencyPairRowProps> = ({ pair, spread, index }) => {
  const [base, quote] = pair.split('/');
  
  const getCountryCode = (currency: string) => {
    const currencyToCountry: Record<string, string> = {
      'EUR': 'eu',
      'USD': 'us',
      'GBP': 'gb',
      'JPY': 'jp',
      'CHF': 'ch',
      'AUD': 'au',
      'CAD': 'ca',
      'NZD': 'nz'
    };
    return currencyToCountry[currency] || 'unknown';
  };

  return (
    <motion.tr 
      className="border-b border-gray-700/50 hover:bg-blue-500/5 transition-all duration-300 cursor-pointer group"
      initial={{ opacity: 0, x: -20 }}
      animate={{ opacity: 1, x: 0 }}
      transition={{ delay: 0.1 * index }}
      whileHover={{ scale: 1.01 }}
    >
      <td className="py-4 pl-4 sm:pl-0">
        <div className="flex items-center space-x-3">
          <div className="flex items-center -space-x-1">
            <img
              src={`https://flagcdn.com/w40/${getCountryCode(base).toLowerCase()}.png`}
              alt={base}
              className="w-6 h-6 rounded-full border-2 border-gray-800"
            />
            <img
              src={`https://flagcdn.com/w40/${getCountryCode(quote).toLowerCase()}.png`}
              alt={quote}
              className="w-6 h-6 rounded-full border-2 border-gray-800"
            />
          </div>
          <span className="text-white font-medium text-sm sm:text-base">{pair}</span>
        </div>
      </td>
      <td className="py-4 pr-4 sm:pr-0">
        <SpreadStatus spread={spread} />
      </td>
    </motion.tr>
  );
};

export default CurrencyPairRow;