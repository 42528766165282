import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/layout/Layout';
import Home from './pages/Home';
import MTX4 from './pages/MTX4';
import Guides from './pages/Guides';
import LoadingScreen from './components/LoadingScreen';

function App() {
  const [isLoading, setIsLoading] = React.useState(true);

  return (
    <>
      {isLoading && <LoadingScreen onLoadingComplete={() => setIsLoading(false)} />}
      <Router>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mtx4" element={<MTX4 />} />
            <Route path="/guides" element={<Guides />} />
          </Routes>
        </Layout>
      </Router>
    </>
  );
}

export default App;