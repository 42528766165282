import React from 'react';
import { motion } from 'framer-motion';
import { ExternalLink } from 'lucide-react';
import { cn } from '../../../utils/cn';
import { useSpreadAnimation } from '../../../hooks/useSpreadAnimation';
import { getSpreadInfo } from '../../../utils/spreadUtils';

interface SpreadStatusProps {
  spread: number;
  className?: string;
}

const SpreadStatus: React.FC<SpreadStatusProps> = ({ spread: initialSpread, className }) => {
  const { spread, trend } = useSpreadAnimation({ initialSpread });
  const info = getSpreadInfo(spread);

  return (
    <div className={cn("relative w-full", className)}>
      <div className={cn(
        "flex items-center justify-between p-3 sm:p-4 rounded-lg backdrop-blur-sm",
        "bg-gradient-to-r",
        info.color,
        "border",
        info.borderColor,
        "gap-2"
      )}>
        <div className="flex items-center gap-2">
          <div>
            <div className="flex items-center gap-1">
              <motion.div 
                className={cn(
                  "text-base sm:text-lg font-bold tabular-nums tracking-wider",
                  info.textColor,
                  trend === 'up' ? 'text-green-400' : 'text-red-400'
                )}
                key={spread.toString()}
                initial={{ opacity: 0.5, y: trend === 'up' ? 10 : -10 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.3 }}
              >
                {spread.toFixed(4)}
              </motion.div>
              <motion.div
                initial={{ opacity: 0, scale: 0.5 }}
                animate={{ opacity: 1, scale: 1 }}
                className={cn(
                  "text-xs font-bold",
                  trend === 'up' ? 'text-green-400' : 'text-red-400'
                )}
              >
                {trend === 'up' ? '▲' : '▼'}
              </motion.div>
            </div>
            <div className="text-xs sm:text-sm text-gray-400 flex items-center gap-1">
              <motion.span 
                className={cn("w-1.5 h-1.5 sm:w-2 sm:h-2 rounded-full", info.pulseColor)}
                animate={{
                  scale: [1, 1.5, 1],
                  opacity: [1, 0.5, 1],
                }}
                transition={{
                  duration: 1.5,
                  repeat: Infinity,
                  ease: "easeInOut"
                }}
              />
              {info.label} Spread
            </div>
          </div>
        </div>

        <a
          href="https://login.hankotrade.com/"
          target="_blank"
          rel="noopener noreferrer"
          className={cn(
            "flex items-center gap-1 px-2 sm:px-4 py-2 rounded-lg",
            "bg-gradient-to-r from-blue-600 to-blue-700",
            "text-white text-sm sm:text-base font-medium whitespace-nowrap",
            "hover:from-blue-700 hover:to-blue-800",
            "transition-all duration-300"
          )}
        >
          Trade
          <ExternalLink className="h-3 w-3 sm:h-4 sm:w-4" />
        </a>
      </div>
    </div>
  );
};

export default SpreadStatus;