import React from 'react';
import { useInView } from 'react-intersection-observer';
import CountUp from 'react-countup';

interface NumberCounterProps {
  end: number;
  suffix?: string;
  prefix?: string;
  duration?: number;
  className?: string;
}

const NumberCounter = ({ 
  end, 
  suffix = '', 
  prefix = '', 
  duration = 2.5,
  className = ''
}: NumberCounterProps) => {
  const { ref, inView } = useInView({
    threshold: 0.1,
    triggerOnce: true,
  });

  return (
    <span ref={ref} className={className}>
      {inView ? (
        <CountUp
          start={0}
          end={end}
          duration={duration}
          separator=","
          prefix={prefix}
          suffix={suffix}
          decimals={end % 1 !== 0 ? 1 : 0}
        />
      ) : (
        <span>{prefix}0{suffix}</span>
      )}
    </span>
  );
};

export default NumberCounter;