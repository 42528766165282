import React from 'react';
import { motion } from 'framer-motion';
import { Sparkles } from 'lucide-react';
import Button from '../ui/Button';
import { GradientText } from '../ui/GradientText';

const MTX4Hero = () => {
  return (
    <div className="relative min-h-[90vh] flex items-center justify-center overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.05)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.05)_1px,transparent_1px)] bg-[size:32px_32px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />
      </div>

      <div className="relative z-10 text-center max-w-5xl mx-auto px-4">
        {/* Partnership Announcement */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6 }}
          className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 md:px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-12"
        >
          <Sparkles className="h-4 w-4 mr-2" />
          Official Partnership Announcement
        </motion.div>

        {/* Partnership Logos */}
        <div className="flex items-center justify-center gap-8 mb-12">
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="relative group"
          >
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-4 border border-gray-700/50 group-hover:border-blue-500/50 transition-all duration-300">
              <motion.img
                src="https://magnofx.com/assets/images/logo.png"
                alt="MagnoFx"
                className="h-8 md:h-12 w-auto"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-blue-500/0 via-blue-500/10 to-blue-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>
          </motion.div>

          <div className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-blue-400 to-purple-400">×</div>

          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6 }}
            className="relative group"
          >
            <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-xl p-4 border border-gray-700/50 group-hover:border-purple-500/50 transition-all duration-300">
              <motion.img
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="Hanko Trade"
                className="h-8 md:h-12 w-auto"
                whileHover={{ scale: 1.05 }}
                transition={{ duration: 0.3 }}
              />
              <div className="absolute inset-0 bg-gradient-to-r from-purple-500/0 via-purple-500/10 to-purple-500/0 opacity-0 group-hover:opacity-100 transition-opacity duration-500" />
            </div>
          </motion.div>
        </div>

        {/* Animated Loader - Adjusted for better visibility */}
        <div className="relative w-32 h-32 md:w-48 md:h-48 mx-auto mb-12">
          <img
            src="https://cp.magnofx.com/assets/images/magnofx-loader.gif"
            alt="MagnoFx Animation"
            className="w-full h-full object-contain opacity-30"
          />
        </div>

        {/* Content */}
        <motion.h1
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.1 }}
          className="text-3xl md:text-6xl lg:text-7xl font-bold mb-4 md:mb-6 leading-tight"
        >
          Experience Trading with{' '}
          <GradientText className="animate-gradient">
            MTX4 Platform
          </GradientText>
        </motion.h1>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
          className="text-base md:text-lg text-gray-400 mb-6 md:mb-8 max-w-2xl mx-auto px-4"
        >
          We are thrilled to announce our official partnership with MagnoFx.com, bringing you the MTX4 platform, 
          a custom-developed desktop trading platform powered by Hankotrade.
        </motion.p>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.3 }}
          className="flex flex-col sm:flex-row items-center justify-center gap-4 px-4"
        >
          <Button 
            size="lg" 
            className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
            href="https://login.hankotrade.com/"
          >
            Start Trading Now
          </Button>
          <Button
            variant="secondary"
            size="lg"
            className="w-full sm:w-auto min-w-[200px]"
            href="https://login.hankotrade.com/"
          >
            Download MTX4
          </Button>
        </motion.div>
      </div>
    </div>
  );
};

export default MTX4Hero;