import React from 'react';
import { Smartphone, Apple, PlayCircle, ChevronRight } from 'lucide-react';
import Button from '../../ui/Button';

const features = [
  "Trade on the go with HankoX's powerful mobile platform",
  "Access real-time market data and execute trades instantly",
  "Stay connected to global markets 24/7 with our secure mobile solution",
  "Advanced charting tools and technical analysis on your mobile device"
];

const MobileAppContent: React.FC = () => {
  return (
    <div>
      <div className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6">
        <Smartphone className="h-4 w-4 mr-2" />
        Trade on the Go • HankoX
      </div>

      <h2 className="text-4xl md:text-5xl font-bold text-white mb-6 leading-tight">
        Experience{' '}
        <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
          Professional Trading
        </span>
        {' '}Anywhere
      </h2>

      <p className="text-gray-300 text-lg mb-8 leading-relaxed">
        Start trading on the go by downloading HankoX from the App Store or Google Play. Just log in with your existing or new credentials, and don't forget to enter "Hankotrade" in the server tab to select your live server. Perfect for both new and experienced traders who want market access at their fingertips!!!
      </p>

      <div className="space-y-4 mb-8">
        {features.map((feature, index) => (
          <div
            key={index}
            className="flex items-start gap-3 group hover:translate-x-1 transition-transform duration-300"
          >
            <div className="flex-shrink-0 p-1.5 bg-gradient-to-br from-blue-500/20 to-purple-500/20 rounded-lg group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
              <ChevronRight className="h-5 w-5 text-blue-400 group-hover:text-blue-300" />
            </div>
            <p className="text-gray-300 group-hover:text-white transition-colors">{feature}</p>
          </div>
        ))}
      </div>

      <div className="flex flex-col sm:flex-row gap-4">
        <Button 
          icon={Apple}
          iconPosition="left"
          className="justify-center group bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800"
        >
          <span className="relative">
            Download on App Store
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white/20 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
          </span>
        </Button>
        <Button 
          variant="secondary"
          icon={PlayCircle}
          iconPosition="left"
          className="justify-center group bg-gradient-to-r from-gray-800 to-gray-900 hover:from-gray-900 hover:to-black"
        >
          <span className="relative">
            Get it on Google Play
            <span className="absolute bottom-0 left-0 w-full h-0.5 bg-white/20 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left" />
          </span>
        </Button>
      </div>

      <div className="mt-8 flex flex-wrap gap-3">
        <span className="px-3 py-1 bg-blue-500/10 rounded-full text-blue-400 text-sm border border-blue-500/20">
          Real-time Data
        </span>
        <span className="px-3 py-1 bg-purple-500/10 rounded-full text-purple-400 text-sm border border-purple-500/20">
          Secure Trading
        </span>
        <span className="px-3 py-1 bg-green-500/10 rounded-full text-green-400 text-sm border border-green-500/20">
          24/7 Support
        </span>
      </div>
    </div>
  );
};

export default MobileAppContent;