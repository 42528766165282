import React from 'react';
import { motion } from 'framer-motion';
import { DollarSign, TrendingUp, Clock, Zap, BarChart2, Shield } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import FadeInWhenVisible from '../animations/FadeInWhenVisible';
import CurrencyPairsTable from './fx-market/CurrencyPairsTable';

const features = [
  {
    icon: DollarSign,
    title: '50+ Currency Pairs',
    description: 'Trade major, minor, and exotic currency pairs'
  },
  {
    icon: TrendingUp,
    title: 'Competitive Spreads',
    description: 'Trade with spreads as low as 0.0 pips'
  },
  {
    icon: Clock,
    title: '24/5 Trading',
    description: 'Access forex markets around the clock'
  },
  {
    icon: Zap,
    title: 'Fast Execution',
    description: 'Ultra-low latency trade execution'
  },
  {
    icon: BarChart2,
    title: 'Advanced Tools',
    description: 'Professional charting and analysis tools'
  },
  {
    icon: Shield,
    title: 'Secure Trading',
    description: 'Protected by bank-grade security'
  }
];

const popularPairs = [
  { pair: 'EUR/USD', spread: 0.0 },
  { pair: 'GBP/USD', spread: 0.1 },
  { pair: 'USD/JPY', spread: 0.1 },
  { pair: 'USD/CHF', spread: 0.2 },
  { pair: 'AUD/USD', spread: 0.2 },
  { pair: 'USD/CAD', spread: 0.3 },
  { pair: 'NZD/USD', spread: 0.3 }
];

const FXMarket = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-gray-900 to-black relative overflow-hidden">
      {/* Animated background grid */}
      <div className="absolute inset-0 bg-grid-pattern bg-[size:32px_32px] opacity-20" />
      
      <Container>
        <FadeInWhenVisible>
          <div className="text-center mb-16">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
            >
              <DollarSign className="h-4 w-4 mr-2" />
              Forex Trading • Multiple Markets
            </motion.div>

            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.1 }}
              className="text-3xl md:text-4xl font-bold text-white mb-4"
            >
              Trade Forex with{' '}
              <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
                Competitive Spreads
              </span>
            </motion.h2>

            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="text-gray-400 text-lg max-w-2xl mx-auto"
            >
              Access the world's largest financial market with ultra-competitive spreads and advanced trading tools
            </motion.p>
          </div>
        </FadeInWhenVisible>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {features.map((feature, index) => (
            <FadeInWhenVisible key={index} delay={0.2 + index * 0.1}>
              <motion.div
                whileHover={{ y: -5 }}
                className="group relative"
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity duration-300" />
                
                <div className="relative bg-gray-800/50 backdrop-blur-sm rounded-lg p-6 hover:bg-gray-800/70 transition-all duration-300 border border-gray-700/50 hover:border-blue-500/50">
                  <div className="flex items-center justify-center w-12 h-12 rounded-lg bg-gradient-to-br from-blue-500/20 to-purple-500/20 mb-6 group-hover:from-blue-500/30 group-hover:to-purple-500/30 transition-colors">
                    <feature.icon className="h-6 w-6 text-blue-400 group-hover:text-blue-300" />
                  </div>
                  
                  <h3 className="text-xl font-semibold text-white mb-2 group-hover:text-blue-400 transition-colors">
                    {feature.title}
                  </h3>
                  
                  <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                    {feature.description}
                  </p>
                </div>
              </motion.div>
            </FadeInWhenVisible>
          ))}
        </div>

        <FadeInWhenVisible>
          <div className="bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 mb-16 border border-gray-700/50">
            <h3 className="text-2xl font-bold text-white mb-6">Popular Currency Pairs</h3>
            <CurrencyPairsTable pairs={popularPairs} />
          </div>
        </FadeInWhenVisible>

        <div className="text-center">
          <Button 
            className="px-8 py-3 text-lg group"
            icon={TrendingUp}
          >
            Start Trading Forex
          </Button>
        </div>
      </Container>
    </section>
  );
};

export default FXMarket;