import React from 'react';
import { motion } from 'framer-motion';
import { Check, Shield, Zap, Users, Clock, DollarSign } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import FadeInWhenVisible from '../animations/FadeInWhenVisible';

const accounts = [
  {
    name: 'STP',
    description: 'Get Access to global markets and commission-free',
    minDeposit: '$10',
    features: [
      'Spreads from 0.7 pips',
      'Maximum leverage 1:500',
      'Zero Commissions',
      'USD, EUR, CAD base currencies',
      'Negative Balance Protection',
      'Minimum lot size 0.01',
      'Swap-free Islamic option available'
    ],
    color: 'from-blue-600/10 to-blue-400/10',
    borderColor: 'border-blue-500/20',
    buttonColor: 'bg-blue-600 hover:bg-blue-700'
  },
  {
    name: 'ECN',
    description: 'Take advantage of tight spreads and competitive commissions',
    minDeposit: '$100',
    features: [
      'Raw spreads from 0.0 pips',
      '$2 per side per $100k traded',
      'Maximum leverage 1:500',
      'USD, EUR, CAD base currencies',
      'Negative Balance Protection',
      'Minimum lot size 0.01',
      'Swap-free Islamic option available'
    ],
    color: 'from-purple-600/10 to-purple-400/10',
    borderColor: 'border-purple-500/20',
    buttonColor: 'bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700',
    highlight: true
  },
  {
    name: 'ECN Plus',
    description: 'Make the most of your trading with ultra low commissions',
    minDeposit: '$1,000',
    features: [
      'Raw spreads from 0.0 pips',
      '$1 per side per $100k traded',
      'Maximum leverage 1:500',
      'USD, EUR, CAD base currencies',
      'Negative Balance Protection',
      'Minimum lot size 0.01',
      'Swap-free Islamic option available'
    ],
    color: 'from-green-600/10 to-green-400/10',
    borderColor: 'border-green-500/20',
    buttonColor: 'bg-green-600 hover:bg-green-700'
  },
  {
    name: 'Islamic',
    description: 'Swap-Free Trading Account',
    minDeposit: '$10',
    features: [
      'No swap or rollover fees',
      'Shariah-compliant trading',
      'Multiple currency pairs available',
      'Maximum leverage 1:500',
      'All trading platforms accessible',
      'Special admin charges apply',
      '24/5 support'
    ],
    color: 'from-amber-600/10 to-amber-400/10',
    borderColor: 'border-amber-500/20',
    buttonColor: 'bg-amber-600 hover:bg-amber-700'
  }
];

const AccountTypes: React.FC = () => {
  return (
    <section className="py-24 bg-gradient-to-b from-black to-gray-900 relative overflow-hidden">
      {/* Background grid pattern */}
      <div className="absolute inset-0 bg-grid-pattern bg-[size:32px_32px] opacity-20" />
      
      <Container>
        <div className="text-center mb-16">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="inline-flex items-center bg-blue-500/10 text-blue-400 px-4 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-500/20 mb-6"
          >
            <Users className="h-4 w-4 mr-2" />
            Trading Accounts
          </motion.div>

          <motion.h2
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-3xl md:text-4xl font-bold text-white mb-4"
          >
            Choose Your{' '}
            <span className="bg-clip-text text-transparent bg-gradient-to-r from-blue-400 via-blue-500 to-purple-600">
              Trading Account
            </span>
          </motion.h2>

          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-gray-400 text-lg max-w-2xl mx-auto"
          >
            Select the account type that best suits your trading style and experience level
          </motion.p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {accounts.map((account, index) => (
            <FadeInWhenVisible key={index} delay={0.2 + index * 0.1}>
              <motion.div
                whileHover={{ y: -5 }}
                className="group relative h-full"
              >
                {/* Card background with gradient border */}
                <div className="absolute inset-0 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity duration-300" />
                
                <div className={`relative h-full bg-gray-800/50 backdrop-blur-sm rounded-lg p-8 hover:bg-gray-800/70 transition-all duration-300 border ${account.borderColor} hover:border-blue-500/50`}>
                  <div className="mb-6">
                    <h3 className="text-2xl font-bold text-white mb-2">{account.name}</h3>
                    <p className="text-gray-400">{account.description}</p>
                  </div>

                  <div className="mb-6">
                    <div className="text-3xl font-bold text-white mb-2">{account.minDeposit}</div>
                    <p className="text-gray-400">Minimum Deposit</p>
                  </div>

                  <div className="space-y-4 mb-8">
                    {account.features.map((feature, featureIndex) => (
                      <div key={featureIndex} className="flex items-start gap-3 group">
                        <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors">
                          <Check className="h-4 w-4 text-blue-500" />
                        </div>
                        <span className="text-gray-300 group-hover:text-white transition-colors">
                          {feature}
                        </span>
                      </div>
                    ))}
                  </div>

                  <Button
                    className={`w-full justify-center ${account.buttonColor}`}
                  >
                    Open Account
                  </Button>
                </div>
              </motion.div>
            </FadeInWhenVisible>
          ))}
        </div>
      </Container>
    </section>
  );
};

export default AccountTypes;