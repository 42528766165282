import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, Shield, AlertCircle } from 'lucide-react';
import Button from '../ui/Button';
import PolicyTab from './PolicyTab';
import { cn } from '../../utils/cn';

interface PolicyModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PolicyModal: React.FC<PolicyModalProps> = ({ isOpen, onClose }) => {
  const [activeTab, setActiveTab] = React.useState<'withdrawal' | 'deposit'>('deposit');

  return (
    <AnimatePresence>
      {isOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center">
          {/* Backdrop */}
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed inset-0 bg-black/80 backdrop-blur-sm"
            onClick={onClose}
          />

          {/* Modal */}
          <motion.div
            initial={{ opacity: 0, scale: 0.95, y: 20 }}
            animate={{ opacity: 1, scale: 1, y: 0 }}
            exit={{ opacity: 0, scale: 0.95, y: 20 }}
            className="relative w-full max-w-[500px] max-h-[90vh] m-4 overflow-hidden"
          >
            <div className="relative">
              {/* Ambient glow */}
              <div className="absolute -inset-2 bg-gradient-to-r from-blue-500/20 to-purple-500/20 rounded-2xl blur-xl opacity-75" />

              {/* Content */}
              <div className="relative bg-gray-900 rounded-xl border border-gray-800 flex flex-col max-h-[80vh]">
                {/* Header */}
                <div className="flex items-center justify-between p-6 border-b border-gray-800">
                  <div className="flex items-center gap-3">
                    <Shield className="h-6 w-6 text-blue-500" />
                    <h2 className="text-xl font-bold text-white">Payment Policy</h2>
                  </div>
                  <button
                    onClick={onClose}
                    className="p-2 text-gray-400 hover:text-white transition-colors"
                  >
                    <X className="h-5 w-5" />
                  </button>
                </div>

                {/* Tabs */}
                <div className="flex border-b border-gray-800">
                  {(['deposit', 'withdrawal'] as const).map((tab) => (
                    <button
                      key={tab}
                      onClick={() => setActiveTab(tab)}
                      className={cn(
                        "flex-1 px-6 py-4 text-sm font-medium transition-colors",
                        activeTab === tab
                          ? "text-blue-400 border-b-2 border-blue-500"
                          : "text-gray-400 hover:text-white"
                      )}
                    >
                      {tab.charAt(0).toUpperCase() + tab.slice(1)} Policy
                    </button>
                  ))}
                </div>

                {/* Scrollable Content */}
                <div className="p-6 overflow-y-auto">
                  <PolicyTab type={activeTab} />

                  {/* Trust Indicators */}
                  <div className="mt-6 p-4 bg-blue-500/10 rounded-lg border border-blue-500/20">
                    <div className="flex items-center gap-2 text-blue-400 mb-2">
                      <AlertCircle className="h-5 w-5" />
                      <span className="font-medium">Trusted by 100K+ traders worldwide</span>
                    </div>
                    <p className="text-sm text-gray-400">
                      Your funds are protected by industry-leading security measures and stored in segregated accounts.
                    </p>
                  </div>

                  {/* CTA Button */}
                  <Button
                    className="w-full mt-6"
                    href="https://login.hankotrade.com/"
                  >
                    Start Trading Now
                  </Button>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}
    </AnimatePresence>
  );
};

export default PolicyModal;