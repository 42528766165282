import React from 'react';
import { Check } from 'lucide-react';

interface PolicyTabProps {
  type: 'withdrawal' | 'deposit';
}

const PolicyTab: React.FC<PolicyTabProps> = ({ type }) => {
  const policies = {
    deposit: {
      mainPoints: [
        'Instant deposits via multiple payment methods',
        'Minimum deposit: $10',
        'No deposit fees',
        'Secure payment processing',
        'Multiple currency options available',
        '24/7 deposit support'
      ],
      currencies: ['EUR', 'GBP', 'USD', 'BTC, LTC, ETH, XRP'],
      terms: [
        'Payments should be made under your name only',
        'No fees charged by Hankotrade for deposits',
        'Currency conversion fees may apply from your bank',
        'Instant processing for most payment methods'
      ]
    },
    withdrawal: {
      mainPoints: [
        'Fast withdrawal processing within 24 hours',
        'Same method withdrawal policy',
        'Verification required for security',
        'No hidden withdrawal fees',
        'Multiple withdrawal methods available',
        'Minimum withdrawal: $10'
      ],
      currencies: ['EUR', 'GBP', 'USD', 'BTC, LTC, ETH, XRP'],
      terms: [
        'Withdrawals processed to same deposit method',
        'Identity verification required for security',
        'Processing within 24 hours on business days',
        'Standard documentation required'
      ]
    }
  };

  return (
    <div className="space-y-6">
      {/* Main Points */}
      <div className="space-y-4">
        {policies[type].mainPoints.map((policy, index) => (
          <div
            key={index}
            className="flex items-start gap-3 group"
          >
            <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors">
              <Check className="h-4 w-4 text-blue-500" />
            </div>
            <span className="text-gray-300 group-hover:text-white transition-colors">
              {policy}
            </span>
          </div>
        ))}
      </div>

      {/* Accepted Currencies */}
      <div className="bg-gray-800/50 rounded-lg p-4 border border-gray-700">
        <h4 className="text-sm font-medium text-white mb-3">Accepted Currencies:</h4>
        <ul className="list-disc list-inside space-y-1">
          {policies[type].currencies.map((currency, index) => (
            <li key={index} className="text-gray-400">{currency}</li>
          ))}
        </ul>
      </div>

      {/* Terms */}
      <div className="space-y-4">
        <h4 className="text-sm font-medium text-white">Important Information:</h4>
        {policies[type].terms.map((term, index) => (
          <div
            key={index}
            className="flex items-start gap-3 group"
          >
            <div className="flex-shrink-0 p-1 bg-blue-500/10 rounded group-hover:bg-blue-500/20 transition-colors">
              <Check className="h-4 w-4 text-blue-500" />
            </div>
            <span className="text-gray-300 group-hover:text-white transition-colors">
              {term}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PolicyTab;