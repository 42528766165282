import React from 'react';
import { motion } from 'framer-motion';
import { 
  DollarSign, 
  Bitcoin, 
  Coins, 
  BarChart2, 
  TrendingUp,
  LineChart
} from 'lucide-react';

const icons = [
  { Icon: DollarSign, color: 'text-green-400', label: 'Forex' },
  { Icon: Bitcoin, color: 'text-orange-400', label: 'Crypto' },
  { Icon: Coins, color: 'text-yellow-400', label: 'Commodities' },
  { Icon: BarChart2, color: 'text-blue-400', label: 'Indices' },
  { Icon: LineChart, color: 'text-purple-400', label: 'Stocks' },
  { Icon: TrendingUp, color: 'text-red-400', label: 'Metals' }
];

const TradingIcons = () => {
  return (
    <div className="flex flex-col items-center justify-center mb-16">
      {/* Logo Section - Increased size */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="relative mb-12 w-full max-w-[300px] md:max-w-[400px]"
      >
        {/* Enhanced background effects */}
        <motion.div
          className="absolute -inset-12 bg-gradient-to-r from-blue-500/20 via-purple-500/20 to-blue-500/20 rounded-full blur-3xl"
          animate={{
            scale: [1, 1.2, 1],
            opacity: [0.3, 0.5, 0.3],
          }}
          transition={{
            duration: 4,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
        
        {/* Logo Container - Enhanced styling */}
        <div className="relative bg-black/50 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-gray-800/50">
          <motion.img 
            src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
            alt="Hanko Trade"
            className="h-12 md:h-16 w-auto mx-auto"
            initial={{ scale: 0.9 }}
            animate={{ scale: 1 }}
            transition={{
              duration: 0.6,
              ease: "easeOut"
            }}
          />
        </div>
      </motion.div>

      {/* Trading Icons - Enhanced grid layout */}
      <div className="relative w-full px-4">
        {/* Enhanced glowing background effect */}
        <div className="absolute inset-0 bg-gradient-to-r from-blue-500/30 via-purple-500/30 to-blue-500/30 blur-3xl opacity-40" />
        
        {/* Icons container - Increased size and spacing */}
        <div className="relative grid grid-cols-3 md:flex md:flex-row items-center justify-center gap-6 md:gap-12 py-6">
          {icons.map(({ Icon, color, label }, index) => (
            <motion.div
              key={label}
              className="group relative"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
            >
              <motion.div
                className="flex flex-col items-center justify-center w-24 h-24 md:w-32 md:h-32 rounded-2xl 
                  bg-gradient-to-br from-gray-800/50 to-gray-900/50 backdrop-blur-sm
                  border border-gray-700/50 group-hover:border-blue-500/50
                  transition-all duration-300"
                whileHover={{ y: -8, scale: 1.05 }}
              >
                {/* Enhanced animated icon */}
                <motion.div
                  animate={{
                    scale: [1, 1.2, 1],
                    rotate: [0, 5, 0],
                  }}
                  transition={{
                    duration: 3,
                    repeat: Infinity,
                    ease: "easeInOut",
                    delay: index * 0.2
                  }}
                  className="relative"
                >
                  <Icon className={`h-8 w-8 md:h-12 md:w-12 ${color}`} />
                  {/* Added glow effect */}
                  <div className={`absolute inset-0 blur-lg opacity-50 ${color}`} />
                </motion.div>
                
                {/* Enhanced label */}
                <span className="text-sm md:text-base text-gray-400 mt-3 group-hover:text-white transition-colors">
                  {label}
                </span>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TradingIcons;