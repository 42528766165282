import axios from 'axios';

const CHANNEL_ID = 'UCoSszOR_kdtMo6E0n4AmNMw';
const API_URL = 'https://www.googleapis.com/youtube/v3';

export interface YouTubeVideo {
  id: string;
  title: string;
  description: string;
  thumbnail: string;
  publishedAt: string;
  duration: string;
}

export async function getChannelVideos(): Promise<YouTubeVideo[]> {
  try {
    // Get channel's uploads playlist ID
    const channelResponse = await axios.get(`${API_URL}/channels`, {
      params: {
        part: 'contentDetails',
        id: CHANNEL_ID,
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      }
    });

    const uploadsPlaylistId = channelResponse.data.items[0].contentDetails.relatedPlaylists.uploads;

    // Get playlist items (videos)
    const playlistResponse = await axios.get(`${API_URL}/playlistItems`, {
      params: {
        part: 'snippet',
        playlistId: uploadsPlaylistId,
        maxResults: 50,
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      }
    });

    // Get video details (including duration)
    const videoIds = playlistResponse.data.items.map((item: any) => item.snippet.resourceId.videoId);
    const videosResponse = await axios.get(`${API_URL}/videos`, {
      params: {
        part: 'contentDetails',
        id: videoIds.join(','),
        key: import.meta.env.VITE_YOUTUBE_API_KEY
      }
    });

    // Map response to our interface
    return playlistResponse.data.items.map((item: any, index: number) => ({
      id: item.snippet.resourceId.videoId,
      title: item.snippet.title,
      description: item.snippet.description,
      thumbnail: item.snippet.thumbnails.high.url,
      publishedAt: item.snippet.publishedAt,
      duration: formatDuration(videosResponse.data.items[index].contentDetails.duration)
    }));
  } catch (error) {
    console.error('Error fetching YouTube videos:', error);
    return [];
  }
}

function formatDuration(duration: string): string {
  const match = duration.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  if (!match) return '0:00';

  const hours = (match[1] || '').replace('H', '');
  const minutes = (match[2] || '').replace('M', '');
  const seconds = (match[3] || '').replace('S', '');

  let result = '';
  if (hours) result += `${hours}:`;
  result += `${minutes || '0'}:`;
  result += seconds.padStart(2, '0');

  return result;
}