import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';
import Container from '../ui/Container';
import Button from '../ui/Button';
import NumberCounter from '../animations/NumberCounter';
import { GradientText } from '../ui/GradientText';

const stats = [
  { number: 50, suffix: '+', label: 'Currency Pairs' },
  { number: 100, suffix: 'K+', label: 'Active Traders' },
  { number: 99.9, suffix: '%', label: 'Execution Rate' },
  { number: 24, suffix: '/7', label: 'Support' }
];

const Hero = () => {
  return (
    <div className="relative min-h-screen bg-black overflow-hidden">
      {/* Enhanced grid background with animated gradient */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[linear-gradient(rgba(59,130,246,0.03)_1px,transparent_1px),linear-gradient(90deg,rgba(59,130,246,0.03)_1px,transparent_1px)] bg-[size:32px_32px] [mask-image:radial-gradient(ellipse_at_center,transparent_20%,black_70%)]" />
        <motion.div
          className="absolute inset-0 bg-gradient-to-r from-blue-950/20 via-purple-950/20 to-blue-950/20"
          animate={{
            backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
          }}
          transition={{
            duration: 10,
            repeat: Infinity,
            ease: "linear"
          }}
        />
      </div>

      {/* Animated glowing orbs */}
      <motion.div
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.1, 0.15, 0.1],
        }}
        transition={{ duration: 8, repeat: Infinity, ease: "linear" }}
        className="absolute top-1/4 -left-32 w-96 h-96 bg-blue-950/30 rounded-full blur-[128px]"
      />
      <motion.div
        animate={{
          scale: [1, 1.3, 1],
          opacity: [0.05, 0.1, 0.05],
        }}
        transition={{ duration: 10, repeat: Infinity, ease: "linear", delay: 1 }}
        className="absolute bottom-1/4 -right-32 w-96 h-96 bg-purple-950/30 rounded-full blur-[128px]"
      />

      <Container className="relative pt-32 pb-16">
        <div className="text-center max-w-4xl mx-auto px-4">
          {/* Logo with enhanced animation */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-8"
          >
            <img 
              src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
              alt="HankoX"
              className="h-12 mx-auto"
            />
          </motion.div>

          {/* Live Markets Badge */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
            className="mb-8"
          >
            <div className="inline-flex items-center bg-blue-950/30 text-blue-400 px-6 py-2 rounded-full text-sm font-medium backdrop-blur-sm border border-blue-900/50">
              <span className="animate-pulse mr-2">●</span>
              Live Markets Open • Start Trading Now
            </div>
          </motion.div>

          {/* Main Heading */}
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.1 }}
            className="text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold mb-6 leading-tight"
          >
            Experience the Future of{' '}
            <GradientText className="animate-gradient">
              Professional Trading
            </GradientText>
          </motion.h1>

          {/* Description */}
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="text-lg text-gray-400 mb-8 max-w-2xl mx-auto px-4"
          >
            Trade with confidence using our advanced platform. Access global markets, real-time data, and professional tools designed for both new and experienced traders.
          </motion.p>

          {/* CTA Buttons */}
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.3 }}
            className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-16 px-4"
          >
            <Button
              size="lg"
              icon={ArrowRight}
              className="w-full sm:w-auto min-w-[200px] bg-gradient-to-r from-blue-700 to-blue-900 hover:from-blue-800 hover:to-blue-950"
            >
              Start Trading Now
            </Button>
            <Button
              variant="secondary"
              size="lg"
              className="w-full sm:w-auto min-w-[200px] bg-gray-900 hover:bg-gray-800"
            >
              Open Demo Account
            </Button>
          </motion.div>

          {/* Stats Grid */}
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4 md:gap-8 px-4">
            {stats.map((stat, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.4 + index * 0.1 }}
                className="relative group"
                whileHover={{ y: -5 }}
              >
                <div className="absolute inset-0 bg-gradient-to-r from-blue-950/30 to-purple-950/30 rounded-lg blur-lg opacity-50 group-hover:opacity-100 transition-opacity" />
                <div className="relative bg-gray-900/50 backdrop-blur-sm rounded-lg p-6 border border-gray-800 group-hover:border-blue-900/50">
                  <div className="text-3xl font-bold text-white mb-2">
                    <NumberCounter end={stat.number} suffix={stat.suffix} />
                  </div>
                  <div className="text-sm text-gray-400">{stat.label}</div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Hero;