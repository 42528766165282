// Trading platform URLs
export const TRADING_URL = 'https://login.hankotrade.com/';
export const DEMO_URL = 'https://login.hankotrade.com/demo';
export const MAGNOFX_URL = 'https://magnofx.com';
export const DOWNLOAD_URL = 'https://login.hankotrade.com/download';
export const SUPPORT_URL = 'mailto:support@hankotrade.com';
export const LIVE_CHAT_URL = 'https://login.hankotrade.com/support';

// Social media URLs
export const SOCIAL_URLS = {
  facebook: 'https://facebook.com/hankotrade',
  twitter: 'https://twitter.com/hankotrade',
  instagram: 'https://instagram.com/hankotrade',
  linkedin: 'https://linkedin.com/company/hankotrade'
} as const;

// Navigation URLs
export const NAV_URLS = {
  home: '/',
  markets: '/markets',
  trading: '/trading',
  about: '/about',
  contact: '/contact',
  mtx4: '/mtx4'
} as const;