import React from 'react';
import { Link } from 'react-router-dom';
import { Instagram, MessageSquare, Mail, Phone } from 'lucide-react';
import Container from '../ui/Container';
import PolicyModal from '../modals/PolicyModal';
import { useModal } from '../../hooks/useModal';
import { TRADING_URL } from '../../utils/constants';

const socialLinks = [
  {
    name: 'Instagram',
    icon: Instagram,
    href: 'https://www.instagram.com/hankotradex',
    label: '@hankotradex'
  },
  {
    name: 'Discord',
    icon: MessageSquare,
    href: 'https://discord.gg/EP394qGPDu',
    label: 'Join Discord'
  }
];

const quickLinks = [
  { name: 'Markets', href: '/#markets' },
  { name: 'Trading', href: '/#trading' },
  { name: 'Accounts', href: '/#accounts' },
  { name: 'Education', href: '/guides' }
];

const Footer = () => {
  const policyModal = useModal();

  return (
    <footer className="bg-black pt-16 pb-8 border-t border-gray-800">
      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12 mb-12">
          {/* Company Info */}
          <div>
            <Link to="/">
              <img 
                src="https://trade.hankotrade.com/static/website/images/hanko-new-logo-white.png"
                alt="HankoTrade"
                className="h-8 mb-6"
              />
            </Link>
            <p className="text-gray-400 mb-6">
              Professional trading platform offering advanced tools and competitive spreads.
            </p>
            <div className="space-y-4">
              {socialLinks.map((social) => (
                <a
                  key={social.name}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center gap-3 text-gray-400 hover:text-blue-400 transition-colors group"
                >
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-gray-800/50 group-hover:bg-blue-500/10 transition-colors">
                    <social.icon className="h-4 w-4" />
                  </div>
                  <span className="text-sm font-medium">{social.label}</span>
                </a>
              ))}
            </div>
          </div>

          {/* Quick Links */}
          <div>
            <h3 className="text-white font-semibold mb-6">Quick Links</h3>
            <ul className="space-y-4">
              {quickLinks.map((link) => (
                <li key={link.name}>
                  <Link
                    to={link.href}
                    className="text-gray-400 hover:text-blue-400 transition-colors"
                  >
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* Contact Info */}
          <div>
            <h3 className="text-white font-semibold mb-6">Contact Us</h3>
            <ul className="space-y-4">
              <li>
                <a
                  href="mailto:support@hankotrade.com"
                  className="flex items-center gap-3 text-gray-400 hover:text-blue-400 transition-colors group"
                >
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-gray-800/50 group-hover:bg-blue-500/10 transition-colors">
                    <Mail className="h-4 w-4" />
                  </div>
                  <span>support@hankotrade.com</span>
                </a>
              </li>
              <li>
                <div className="flex items-center gap-3 text-gray-400 group">
                  <div className="flex items-center justify-center w-8 h-8 rounded-lg bg-gray-800/50 group-hover:bg-blue-500/10 transition-colors">
                    <Phone className="h-4 w-4" />
                  </div>
                  <span>24/7 Support</span>
                </div>
              </li>
            </ul>
          </div>

          {/* Trading */}
          <div>
            <h3 className="text-white font-semibold mb-6">Trading</h3>
            <ul className="space-y-4">
              <li>
                <a
                  href={TRADING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Open Live Account
                </a>
              </li>
              <li>
                <a
                  href={TRADING_URL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Open Demo Account
                </a>
              </li>
              <li>
                <Link
                  to="/mtx4"
                  className="text-gray-400 hover:text-blue-400 transition-colors"
                >
                  Download MTX4
                </Link>
              </li>
            </ul>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <p className="text-gray-400 text-sm">
              © {new Date().getFullYear()} HankoTrade. All rights reserved.
            </p>
            <div className="flex space-x-6">
              <button 
                onClick={policyModal.open}
                className="text-gray-400 hover:text-blue-400 transition-colors text-sm"
              >
                Withdrawal Policy
              </button>
              <button 
                onClick={policyModal.open}
                className="text-gray-400 hover:text-blue-400 transition-colors text-sm"
              >
                Deposit Policy
              </button>
            </div>
          </div>
          
          <div className="mt-6 text-center">
            <p className="text-gray-500 text-sm">
              Trading in financial instruments carries a high level of risk to your capital with the possibility of losing more than your initial investment. Trading is not suitable for everyone and requires knowledge and experience.
            </p>
          </div>
        </div>
      </Container>

      <PolicyModal 
        isOpen={policyModal.isOpen} 
        onClose={policyModal.close} 
      />
    </footer>
  );
};

export default Footer;